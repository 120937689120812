<template>
  <div v-if="user.id" class="bg-white py-6 px-4 sm:p-6 shadow rounded-t">
    <form
      id="user-information"
      @submit.prevent="handleSubmit"
      class="space-y-8 mb-8"
    >
      <div class="">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Personal information
          </h3>
          <p class="mt-1 text-sm text-gray-500">
            Use a permanent address where you can receive mail.
          </p>
        </div>
        <div className="sm:col-span-6 py-6">
          <label
            htmlFor="photo"
            className="block text-sm font-medium text-blue-gray-700"
          >
            Avatar
          </label>
          <div className="mt-1 flex items-center">
            <Avatar
              :user="user"
              :className="['inline-block', 'h-12', 'w-12', 'rounded-full']"
            />
            <div className="ml-4 flex">
              <div
                className="relative bg-white py-2 px-3 border border-blue-gray-300 rounded-md shadow-sm flex items-center cursor-pointer hover:bg-blue-gray-50 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 focus-within:ring-blue-500"
              >
                <label
                  htmlFor="user_photo"
                  className="relative text-sm font-medium text-blue-gray-900 pointer-events-none"
                >
                  <span>Change</span>
                  <span className="sr-only"> user photo</span>
                </label>
                <input
                  accept="image/png, image/jpeg"
                  @change="handleImageUpload"
                  id="user_photo"
                  name="user_photo"
                  type="file"
                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-6 gap-6">
          <div class="col-span-6 sm:col-span-3">
            <label
              for="first_name"
              class="block text-sm font-medium text-gray-700"
              >First name</label
            >
            <input
              type="text"
              name="first_name"
              id="first_name"
              autocomplete="given-name"
              v-model="user.first_name"
              class="
                mt-1
                block
                w-full
                border border-gray-300
                rounded-md
                shadow-sm
                py-2
                px-3
                focus:outline-none
                focus:ring-blue-500
                focus:border-blue-500
                sm:text-sm
              "
            />
          </div>
          <div class="col-span-6 sm:col-span-3">
            <label
              for="last_name"
              class="block text-sm font-medium text-gray-700"
              >Last name</label
            >
            <input
              type="text"
              name="last_name"
              v-model="user.last_name"
              id="last_name"
              autocomplete="family-name"
              class="
                mt-1
                block
                w-full
                border border-gray-300
                rounded-md
                shadow-sm
                py-2
                px-3
                focus:outline-none
                focus:ring-blue-500
                focus:border-blue-500
                sm:text-sm
              "
            />
          </div>

          <div class="col-span-6 sm:col-span-3">
            <label
              for="email_address"
              class="block text-sm font-medium text-gray-700"
              >Email address</label
            >
            <input
              v-model="user.email"
              type="text"
              name="email_address"
              id="email_address"
              autocomplete="email"
              class="
                mt-1
                block
                w-full
                border border-gray-300
                rounded-md
                shadow-sm
                py-2
                px-3
                focus:outline-none
                focus:ring-blue-500
                focus:border-blue-500
                sm:text-sm
              "
            />
          </div>
          <div class="col-span-6 sm:col-span-3">
            <div>
              <label
                for="phone_number"
                class="block text-sm font-medium text-gray-700"
                >Phone Number</label
              >
              <div class="mt-1 relative rounded-md shadow-sm">
                <input
                  type="text"
                  name="phone_number"
                  v-model="user.info.phone"
                  id="phone_number"
                  class="
                    focus:ring-blue-500
                    focus:border-blue-500
                    block
                    w-full
                    sm:text-sm
                    border-gray-300
                    rounded-md
                  "
                  placeholder="(XXX) XXX-XXXX"
                />
              </div>
            </div>
          </div>
          <div class="col-span-6 sm:col-span-3">
            <label for="country" class="block text-sm font-medium text-gray-700"
              >Country / Region</label
            >
            <select
              id="country"
              name="country"
              v-model="user.info.address.country"
              autocomplete="country"
              class="
                mt-1
                block
                w-full
                bg-white
                border border-gray-300
                rounded-md
                shadow-sm
                py-2
                px-3
                focus:outline-none
                focus:ring-blue-500
                focus:border-blue-500
                sm:text-sm
              "
            >
              <option value="UK">United Kingdom</option>
              <option value="CA">Canada</option>
            </select>
          </div>

          <div class="col-span-6 sm:col-span-3">
            <label
              for="street_address"
              class="block text-sm font-medium text-gray-700"
              >Street address</label
            >
            <input
              type="text"
              name="street_address"
              id="street_address"
              v-model="user.info.address.street_address"
              autocomplete="street-address"
              class="
                mt-1
                block
                w-full
                border border-gray-300
                rounded-md
                shadow-sm
                py-2
                px-3
                focus:outline-none
                focus:ring-blue-500
                focus:border-blue-500
                sm:text-sm
              "
            />
          </div>
          <div class="col-span-6 sm:col-span-6 lg:col-span-2">
            <label for="city" class="block text-sm font-medium text-gray-700"
              >City</label
            >
            <input
              type="text"
              v-model="user.info.address.city"
              name="city"
              id="city"
              class="
                mt-1
                block
                w-full
                border border-gray-300
                rounded-md
                shadow-sm
                py-2
                px-3
                focus:outline-none
                focus:ring-blue-500
                focus:border-blue-500
                sm:text-sm
              "
            />
          </div>

          <div class="col-span-6 sm:col-span-3 lg:col-span-2">
            <label for="state" class="block text-sm font-medium text-gray-700"
              >State / Province</label
            >
            <input
              type="text"
              name="state"
              id="state"
              v-model="user.info.address.state"
              class="
                mt-1
                block
                w-full
                border border-gray-300
                rounded-md
                shadow-sm
                py-2
                px-3
                focus:outline-none
                focus:ring-blue-500
                focus:border-blue-500
                sm:text-sm
              "
            />
          </div>

          <div class="col-span-6 sm:col-span-3 lg:col-span-2">
            <label
              for="postal_code"
              class="block text-sm font-medium text-gray-700"
              >Postcode</label
            >
            <input
              type="text"
              name="postal_code"
              id="postal_code"
              autocomplete="postal-code"
              v-model="user.info.address.postcode"
              class="
                mt-1
                block
                w-full
                border border-gray-300
                rounded-md
                shadow-sm
                py-2
                px-3
                focus:outline-none
                focus:ring-blue-500
                focus:border-blue-500
                sm:text-sm
              "
            />
          </div>
        </div>
      </div>
    </form>
  </div>
  <div
    class="
      space-x-4
      flex
      justify-end
      bg-gray-50
      py-6
      px-4
      sm:p-6
      shadow
      rounded-b
    "
  >
    <button
      class="
        inline-flex
        items-center
        px-4
        py-2
        border border-transparent
        shadow-sm
        text-sm
        font-medium
        rounded-md
        text-blue-700
        bg-blue-100
        hover:bg-blue-200
        focus:outline-none
        focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500
      "
    >
      Cancel
    </button>
    <button
      type="submit"
      form="user-information"
      class="
        inline-flex
        items-center
        px-4
        py-2
        border border-transparent
        shadow-sm
        text-sm
        font-medium
        rounded-md
        text-white
        bg-light-blue-600
        hover:bg-light-blue-700
        focus:outline-none
        focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500
      "
    >
      Save
    </button>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { reactive, computed } from "vue";
import { upload } from "../../../services/action";
import { useToast } from "vue-toastification";
import Avatar from "../../../components/Avatar";
export default {
  components: {
    Avatar,
  },
  setup() {
    const store = useStore();
    const toast = useToast();

    const user = reactive(
      computed({
        get: () => JSON.parse(JSON.stringify(store.getters["user/user"])),
        set: (val) => {
          user.value = val;
        },
      })
    );

    const handleImageUpload = async (event) => {
      try {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append("files", file);
        const {
          data: [data],
        } = await upload(formData);
        event.target.value = "";
        user.value.avatar = data;
        toast.success("Image uploaded successfully!");
      } catch (e) {
        console.log(e);
      }
    };

    const handleSubmit = async () => {
      await store.dispatch("user/updateMe", user.value);
      toast.success("Update successfully!");
    };

    return {
      user,
      handleImageUpload,
      handleSubmit,
      Avatar,
    };
  },
};
</script>

